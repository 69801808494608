<template>
    <div v-if="lengthToggle" class=" mb-4 md:mb-auto dark:bg-gray-800 border-l-4 border-gray-600 bg-gray-100  text-black-700 p-4 w-full"><svg class="w-4 float-left flex py-1.5 mr-3.5" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
<path  fill-rule="evenodd" clip-rule="evenodd"  d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM9 4C9 4.55228 8.55228 5 8 5C7.44772 5 7 4.55228 7 4C7 3.44772 7.44772 3 8 3C8.55228 3 9 3.44772 9 4ZM7 7C6.44772 7 6 7.44772 6 8C6 8.55229 6.44772 9 7 9V12C7 12.5523 7.44772 13 8 13H9C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11V8C9 7.44772 8.55228 7 8 7H7Z" fill="currentColor"/>
</svg>
 
      <p class="pt-1 text-sm sentence-case whitespace-pre-wrap">{{isContenShort(content)}} {{ renderEllipses(content) }} <button v-if="isBioLong(content)" class=" text-right items-center  text-sm font-medium rounded underline bg-transparent focus:outline-none dark:text-white float-right text-sm" @click="lengthToggle = false">Read more</button></p>
      
    </div>
    <div v-if="!lengthToggle" class="dark:bg-gray-800 border-l-4 border-gray-600 bg-gray-100  text-black-700 p-4 w-full">
      <svg class="w-4 float-left flex py-1.5 mr-3.5" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM9 4C9 4.55228 8.55228 5 8 5C7.44772 5 7 4.55228 7 4C7 3.44772 7.44772 3 8 3C8.55228 3 9 3.44772 9 4ZM7 7C6.44772 7 6 7.44772 6 8C6 8.55229 6.44772 9 7 9V12C7 12.5523 7.44772 13 8 13H9C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11V8C9 7.44772 8.55228 7 8 7H7Z" fill="currentColor"/>
     </svg>
      <p class=" pt-1 text-sm flow-root whitespace-pre-wrap">{{ content }} <button v-if="isBioLong(content)" class="text-right items-center  text-sm font-medium rounded underline bg-transparent focus:outline-none  dark:text-white float-right text-sm" @click="lengthToggle = true">Show less</button></p>
      
    </div>
</template>


<script>
// import {ExclamationCircleIcon} from '@heroicons/vue/outline'

export default {
    name: "ExpandableBlockContent",
    components:{},
    props: {
      content: { type: String,required: true },
      visibleLines: { type: Number, default: 3 },
    },
    data () {
    return {
        lengthToggle:true,
      
    }
  },
  methods: {
    isContenShort(content) {
      //  This prevents rendering the 'See More/Less' buttons
      //  when they are not needed.
      return content.substr(0, 120);
    },
    isBioLong(content) {
      //  This prevents rendering the 'See More/Less' buttons
      //  when they are not needed.
      return content?.length > 100;
    },
    renderEllipses(content) {
      //  Shows ellipses if bio is longer than 500 chars
      if (this.isBioLong(content)) {
        return "..."
      } else {
        return;
      }
    },
  },
}
</script>

<style>
  .description-info {
    width: 400px;
  }
  .readLess {
    height: 109px;
    overflow: hidden;
  }
  .read-more, .read-less {
    background-color: green;
    display: inline-block;
    color: white;
  }
</style>




