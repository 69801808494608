<template>
    <div @mouseleave="close()" class="block relative ">
        <div class="flex flex-row items-center " v-if="showTitle">
          <h3 class="py-1 text-sm text-gray-800 dark:text-white pr-1">{{heading}}</h3>
          <span class="" title="Feel free to add a new option!"> <BaseToolTip v-if="tooltip" :text=toolText> </BaseToolTip></span>
        </div>
        <div :class="inputClass()" class="flex items-center shadow-sm eye-input border-gray-300 text-sm rounded-md">
            <input @click.stop="showOptions=true" @focus="showOptions=true" :value="value" 
                @input="onInput"
                @keyup.enter="close()"
                class="cursor-pointer rounded-r-md h-9 w-full p-2 text-gray-900
                bg-transparent dark:text-white capitalize" />
            <ChevronDownIcon @click.stop="showOptions=!showOptions" class="w-7 pr-2 text-gray-400 
                dark:bg-primaryDark dark:text-white" />
        </div>
        <div v-show="showOptions" @click.stop
            class="absolute block flex flex-col items-center 
            bg-white overflow-auto min-w-46 eye-input border-gray-500 w-full max-h-56 z-10
            dark:bg-primaryDark dark:text-white  ">
            <div v-for="option, idx in filteredOptions" :ref="option" :key="idx" @click.stop="$emit('update:value', option); close()"
                class="block w-46 text-sm p-2 w-full flex items-center 
                  hover:bg-gray-200 dark-hover:bg-gray-500 dark-hover:text-white"
                :class="[ option === value ? 'bg-gray-200 dark:bg-gray-500' : '']">
                {{ option }}
            </div>
        </div>
    </div>  
</template>

<script>
import { ref, computed } from 'vue'
import { ChevronDownIcon } from '@heroicons/vue/solid/esm'
import BaseToolTip from "@/components/widgets/BaseToolTip.vue";
export default {
    name:"DropdownWithCreate",
    components: {ChevronDownIcon,BaseToolTip},
    props:{
        options: Array,
        value: [String, Boolean],
        heading: String,
        showTitle: {
          required: false,
          default: true,
          type: Boolean  
        },
        version: {
            required: false,
            default: "1"
        },
        toolText:String,
        tooltip: {
        type: Boolean,
        default: false
    },
    },
    setup(props, { emit }){
        const showOptions = ref(false)
        const hasStartedTyping = ref(false)
        const filteredOptions = computed(() => props.value === "" || !hasStartedTyping.value
            ? props.options : props.options.filter(s => s.toLowerCase().includes(props.value.toLowerCase())))
        return {
            filteredOptions,
            showOptions,
            hasStartedTyping,
            autoSuggestion: computed(() => props.value ? props.options.filter(el => el.toLowerCase()
                .includes(props.value.toLowerCase())
            )[0]: ""),
            onInput(e) {
                hasStartedTyping.value = true
                emit('update:value', e.target.value)
            }
        }
    },
    methods: {
        close(){
            this.showOptions = false
            this.hasStartedTyping = false
        },
        inputClass(){
            if(this.version == "2")  return "h-12 w-48"
            return "w-full"
        }
    },
    watch: {
        showOptions: function(val) { // Scroll to value automatically
            let element = this.$refs[this.$props.value]
            if(val && element) this.$nextTick(() => element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start'
                }
            ))
        }
    },
    mounted() {
        document.addEventListener('click', this.close)
    },
    beforeUnmount(){
        document.removeEventListener('click', this.close)
    }
}
</script>

