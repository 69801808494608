<template>
    <div class="pt-4">
        <div class="md:col-span-3 sm:col-span-4 sm:col-start-2 grid grid-cols-4 gap-2 dark:text-white">
            <div class="col-span-4 grid sm:grid-cols-2">
                <!-- <ListSelector class="" @onSubmit="setJobs" :submit="submitActive" header="Job title"
                    :items="registeredJobs" :selectedItems="selectedJobs" /> -->
                <div v-if="professionalName" class="col-span-2 grid grid-cols-2 px-4 pb-4">
                    <p class="col-span-2">Select name to display in header</p>
                    <div class="pr-4">
                        <!-- <p class="">Show given name</p> -->
                        <button @click="form.professionalName = false" class="transition duration-100 relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left border sm:text-sm"
                            :class="[!form.professionalName ? darkModeEnabled ? 'bg-gray-700 text-white' : 'bg-gray-700 text-white' : darkModeEnabled ? 'bg-primaryDark' : 'border-gray-300']">
                            <p class="text-xs">Given name</p>
                            <p>{{ fullName }}</p>
                            </button>
                    </div>
                    <div class="pl-4">
                        <!-- <p>Show professional name</p> -->
                        <button @click="form.professionalName = true" class="transition duration-100 relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left border sm:text-sm"
                            :class="[form.professionalName ? darkModeEnabled ? 'bg-gray-700 text-white' : 'bg-gray-700 text-white' : darkModeEnabled ? 'bg-primaryDark' : 'border-gray-300']">
                            <p class="text-xs">Professional name</p>
                            <p>{{ professionalName }}</p>
                            </button>
                    </div>
                </div>
                <div v-if="registeredJobs?.length > 0" class="p-4">
                <Listbox v-model="selectedJobs" multiple name="Job">
                    <ListboxLabel>Select Jobs</ListboxLabel>
                    <div class="relative mt-1">
                        <ListboxButton
                            :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxButton',]">
                            <span v-if="selectedJobs.length > 0" class="block truncate sm:w-full w-56">{{ selectedJobs.map((job) => job).join(', ') }}</span>
                            <span v-else class="block truncate">Select Jobs</span>
                            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </ListboxButton>

                        <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                            leave-to-class="opacity-0">
                            <ListboxOptions
                                :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxOptions',]">
                                <ListboxOption v-slot="{ active, selected }" v-for="job, idx in registeredJobs" :key="idx"
                                    :value="job" as="template">
                                    <li :class="[
                                        active ? darkModeEnabled ? 'bg-gray-700' : 'bg-gray-200' : darkModeEnabled ? 'text-white' : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-10 pr-4',
                                    ]">
                                        <span :class="[
                                            selected ? 'font-medium' : 'font-normal',
                                            'block truncate',
                                        ]">{{ job }}</span>
                                        <span v-if="selected"
                                            :class="[darkModeEnabled ? 'text-white' : '', 'absolute inset-y-0 left-0 flex items-center pl-3']"
                                            >
                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    </li>
                                </ListboxOption>
                            </ListboxOptions>
                        </transition>
                    </div>
                </Listbox>
                </div>
                <!-- <ListSelector @onSubmit="setAddresses" :submit="submitActive" header="Location" :items="addresses"
                    :selectedItems="selectedAddresses" /> -->
                <div v-if="addresses?.length > 0" class="p-4">
                    <Listbox v-model="selectedAddresses" multiple name="Address">
                    <ListboxLabel class="">Select Locations</ListboxLabel>
                    <div class="relative mt-1">
                        <ListboxButton
                            :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxButton',]">
                            <span v-if="selectedAddresses.length > 0" class="block truncate">{{ selectedAddresses.map((a) => a).join(', ') }}</span>
                            <span v-else class="block truncate">Select Address</span>
                            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </ListboxButton>

                        <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                            leave-to-class="opacity-0">
                            <ListboxOptions
                                :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxOptions',]">
                                <ListboxOption v-slot="{ active, selected }" v-for="address, idx in addresses" :key="idx"
                                    :value="address" as="template">
                                    <li :class="[
                                        active ? darkModeEnabled ? 'bg-gray-700' : 'bg-gray-200' : darkModeEnabled ? 'text-white' : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-10 pr-4',
                                    ]">
                                        <span :class="[
                                            selected ? 'font-medium' : 'font-normal',
                                            'block truncate',
                                        ]">{{  address }}</span>
                                        <span v-if="selected"
                                            :class="[darkModeEnabled ? 'text-white' : '', 'absolute inset-y-0 left-0 flex items-center pl-3']"
                                            >
                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    </li>
                                </ListboxOption>
                            </ListboxOptions>
                        </transition>
                    </div>
                </Listbox>
                </div>
                <!-- <ListSelector @onSubmit="setPhone" :submit="submitActive" header="Telephone (select one)"
                    :items="phoneNumbers" :selectedItems="selectedPhone" :singleValue="true" /> -->
                <div v-if="phoneNumbers?.length > 0" class="p-4">
                    <Listbox v-model="selectedPhone">
                    <ListboxLabel class="">Select Primary Phone</ListboxLabel>
                    <div class="relative mt-1">
                        <ListboxButton
                            :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxButton',]">
                            <span class="block truncate">{{ selectedPhone }}</span>
                            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </ListboxButton>

                        <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                            leave-to-class="opacity-0">
                            <ListboxOptions
                                :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxOptions',]">
                                <ListboxOption v-slot="{ active, selected }" v-for="phone in phoneNumbers" :key="phone.id"
                                    :value="phone.phoneNumber" as="template">
                                    <li :class="[
                                        active ? darkModeEnabled ? 'bg-gray-700' : 'bg-gray-200' : darkModeEnabled ? 'text-white' : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-10 pr-4',
                                    ]">
                                        <span :class="[
                                            selected ? 'font-medium' : 'font-normal',
                                            'block truncate',
                                        ]">{{ phone.phoneNumber }}</span>
                                        <span v-if="selected"
                                            :class="[darkModeEnabled ? 'text-white' : '', 'absolute inset-y-0 left-0 flex items-center pl-3']"
                                            >
                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    </li>
                                </ListboxOption>
                            </ListboxOptions>
                        </transition>
                    </div>
                </Listbox>
                </div>
                <!-- <ListSelector @onSubmit="setEmails" :submit="submitActive" header="Email (select one)" :items="emails"
                    :selectedItems="selectedEmails" :singleValue="true" /> -->
                <div v-if="emails?.length > 0" class="p-4">
                    <Listbox v-model="selectedEmails">
                    <ListboxLabel class="">Select Primary Email</ListboxLabel>
                    <div class="relative mt-1">
                        <ListboxButton
                            :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxButton',]">
                            <span class="block truncate">{{ selectedEmails }}</span>
                            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </ListboxButton>

                        <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                            leave-to-class="opacity-0">
                            <ListboxOptions
                                :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxOptions',]">
                                <ListboxOption v-slot="{ active, selected }" v-for="email in emails" :key="email.id"
                                    :value="email.email" as="template">
                                    <li :class="[
                                        active ? darkModeEnabled ? 'bg-gray-700' : 'bg-gray-200' : darkModeEnabled ? 'text-white' : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-10 pr-4',
                                    ]">
                                        <span :class="[
                                            selected ? 'font-medium' : 'font-normal',
                                            'block truncate',
                                        ]">{{ email.email }}</span>
                                        <span v-if="selected"
                                            :class="[darkModeEnabled ? 'text-white' : '', 'absolute inset-y-0 left-0 flex items-center pl-3']"
                                            >
                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    </li>
                                </ListboxOption>
                            </ListboxOptions>
                        </transition>
                    </div>
                </Listbox>
                </div>
                <!-- <ListSelector @onSubmit="setWebsites" :submit="submitActive" header="Website (select one)"
                    :items="websites" :selectedItems="selectedWebsites" :singleValue="true" /> -->
                <div v-if="websites?.length > 0" class="p-4">
                    <Listbox v-model="selectedWebsites">
                    <ListboxLabel class="">Select Primary Link</ListboxLabel>
                    <div class="relative mt-1">
                        <ListboxButton
                            :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxButton',]">
                            <span class="block truncate">{{ selectedWebsites }}</span>
                            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </ListboxButton>

                        <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                            leave-to-class="opacity-0">
                            <ListboxOptions
                                :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxOptions',]">
                                <ListboxOption v-slot="{ active, selected }" v-for="website in websites" :key="website.id"
                                    :value="website.url" as="template">
                                    <li :class="[
                                        active ? darkModeEnabled ? 'bg-gray-700' : 'bg-gray-200' : darkModeEnabled ? 'text-white' : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-10 pr-4',
                                    ]">
                                        <span :class="[
                                            selected ? 'font-medium' : 'font-normal',
                                            'block truncate',
                                        ]">{{ website.url }}</span>
                                        <span v-if="selected"
                                            :class="[darkModeEnabled ? 'text-white' : '', 'absolute inset-y-0 left-0 flex items-center pl-3']"
                                            >
                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    </li>
                                </ListboxOption>
                            </ListboxOptions>
                        </transition>
                    </div>
                </Listbox>
                </div>
                <!-- <ListSelector @onSubmit="setSocials" :submit="submitActive" header="Social media" :items="socials"
                    :selectedItems="selectedSocials" /> -->
                <div v-if="socials?.length > 0" class="p-4">
                    <Listbox v-model="selectedSocials" multiple name="Job">
                    <ListboxLabel>Select Social Media to display</ListboxLabel>
                    <div class="relative mt-1">
                        <ListboxButton
                            :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxButton',]">
                            <span v-if="selectedSocials.length > 0" class="block truncate">{{ selectedSocials.map((job) => job).join(', ') }}</span>
                            <span v-else class="block truncate">Select Social Media</span>
                            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </ListboxButton>

                        <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                            leave-to-class="opacity-0">
                            <ListboxOptions
                                :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'listboxOptions',]">
                                <ListboxOption v-slot="{ active, selected }" v-for="social, idx in socials" :key="idx"
                                    :value="social" as="template">
                                    <li :class="[
                                        active ? darkModeEnabled ? 'bg-gray-700' : 'bg-gray-200' : darkModeEnabled ? 'text-white' : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-10 pr-4',
                                    ]">
                                        <span :class="[
                                            selected ? 'font-medium' : 'font-normal',
                                            'block truncate',
                                        ]">{{ social }}</span>
                                        <span v-if="selected"
                                            :class="[darkModeEnabled ? 'text-white' : '', 'absolute inset-y-0 left-0 flex items-center pl-3']"
                                            >
                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    </li>
                                </ListboxOption>
                            </ListboxOptions>
                        </transition>
                    </div>
                </Listbox>
                </div>
            <div>
                <p></p>
            </div>
            </div>
            <SaveCancelForm class="col-span-2 col-start-3" :display="true" justifyOrientation="end" @onSave="onSubmit"
                @onCancel="$emit('onClose')" />
        </div>
    </div>
</template>

<script>
/* eslint-disable no-unreachable */
import { useStore } from 'vuex'
import { reactive, ref, computed } from 'vue'
import SaveCancelForm from '@/components/forms/SaveCancelForm'
// import ListSelector from '@/components/inputs/ListSelector'
import {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
} from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import capitalize from "../../../../utils/Capitalize";
import { PhotoAlbumNames } from '@/constants.js'

export default {
    name: "ProfileHeaderEdit",
    components: { SaveCancelForm, Listbox, ListboxLabel,
     ListboxButton, ListboxOptions, ListboxOption, CheckIcon, SelectorIcon },
    methods: {
    sentenceCase(string) {
      //  Makes the first char of a string uppercase
      //  and the following chars lowercase
      return capitalize(string)
    },
  },
    setup(props, { emit }) {
        const store = useStore()
        const header = store.getters.getUserHeader

        const darkModeEnabled = ref(store.getters.getDarkMode)

        store.dispatch("fetchUserAlbum", PhotoAlbumNames.ProfilePhotos) // Fetch profile pictures

        const selectedJobs = ref(header.jobs)
        const selectedAddresses = ref(store.getters.getProfileCities)
        const selectedPhone = ref(store.getters.getSortedProfilePhoneNumbers[0]?.phoneNumber)

        const selectedEmails = ref(store.getters.getSortedProfileEmails[0]?.email)
        const selectedWebsites = ref(store.getters.getSortedProfileWebsites[0]?.url)
        const selectedSocials = ref(store.getters.getSocials.map(s => s.network))
        const form = reactive({
            image: {
                preview: store.getters.getProfilePicture,
                image: null,
                coordinates: {
                    width: 0,
                    height: 0,
                    left: 0,
                    top: 0
                }
            },
            professionalName: store.getters.getUserDisplayProfessionalName,
            givenName: !store.getters.getUserDisplayProfessionalName
        })
        const submitActive = ref(false)
        const fileToBig = ref(false)
        const imagePoiSelectorOn = ref(false)

        function closePhotoWidget() {
            form.image = {
                preview: store.getters.getProfilePicture,
                image: null,
                coordinates: {
                    width: 0,
                    height: 0,
                    left: 0,
                    top: 0
                }
            }
            imagePoiSelectorOn.value = false
        }

        return {
            closePhotoWidget,
            store,
            form,
            darkModeEnabled,
            submitActive,
            imagePoiSelectorOn,
            fileToBig,
            profilePicture: computed(() => store.getters.getUserProfilePicture),
            profilePictures: computed(() => store.getters.getUserAlbum(PhotoAlbumNames.ProfilePhotos)),
            isLoading: computed(() => store.getters.isLoading),
            async submitPhoto({ file, crop, croppedFile }) {
                if (file || croppedFile) {
                    await store.dispatch("createMediaImage", 
                         { file,
                           crop,
                           croppedFile,
                           logo: true, 
                           profileId: store.getters.getId,
                           album: PhotoAlbumNames.ProfilePhotos
                        })

                store.dispatch("fetchUserAlbum", PhotoAlbumNames.ProfilePhotos) // Fetch profile pictures
                closePhotoWidget()
                }

            },
            async selectPhotoAsLogo(uuid) {
                await store.dispatch("editMediaImage", { uuid, logo: true })
                store.dispatch("fetchUserProfilePicture")
                imagePoiSelectorOn.value = false

            },

            async onSubmit() {
                submitActive.value = true
                function delay(ms) {
                    return new Promise(resolve => setTimeout(resolve, ms));
                }
                await delay(200);

                await store.dispatch("editProfile", { displayProfessionalName: form.professionalName })
                // Jobs
                let jobIds = store.getters.getUserSecondaryJobs.filter(job => selectedJobs?.value.includes(job.title))
                jobIds = jobIds.map(job => job.id)
                await store.dispatch("setSecondaryJobPrimary", jobIds)
                // Addresses
                let addressIds = store.getters.getUserAddresses.filter(address => selectedAddresses.value?.includes(address.city))
                addressIds = addressIds.map(address => address.id)
                if (addressIds.length > 0) await store.dispatch("setAddressPrimary", addressIds)
                // Phone
                let phoneIds = store.getters.getPhoneNumbers.filter(phone => selectedPhone.value?.includes(phone.phoneNumber))
                phoneIds = phoneIds.map(phone => phone.id)
                if (phoneIds.length > 0) await store.dispatch("setPhoneNumberPrimary", phoneIds)
                // Emails
                let emailIds = store.getters.getEmails.filter(email => selectedEmails.value?.includes(email.email))
                emailIds = emailIds.map(email => email.id)
                if (emailIds.length > 0) await store.dispatch("setEmailPrimary", emailIds)
                // Websites
                let websiteIds = store.getters.getUserWebsites.filter(website => selectedWebsites.value?.includes(website.url))
                websiteIds = websiteIds.map(website => website.id)
                if (websiteIds.length > 0) await store.dispatch("setWebsitePrimary", websiteIds)
                // Socials
                let socialIds = store.getters.getSocials.filter(social => selectedSocials.value?.includes(social.network))
                socialIds = socialIds.map(social => social.id)
                if (socialIds.length > 0) await store.dispatch("setSocialPrimary", socialIds)
                // Profile Picture
                //                if(form.image.preview !== store.getters.getProfilePicture) {
                //                    await store.dispatch("createMediaImage", {file:form.image.file,
                //                                                              logo: true,
                //                                                              profileId: store.getters.getId})
                //                }

                await store.dispatch("fetchProfile", store.getters.getId)
                emit('onClose')

            },
            // Selected
            selectedJobs,
            selectedAddresses,
            selectedPhone,
            selectedEmails,
            selectedWebsites,
            selectedSocials,

            // Setters
            setJobs: val => selectedJobs.value = val.value,
            setAddresses: val => selectedAddresses.value = val.value,
            setPhone: val => selectedPhone.value = val.value,
            setEmails: val => selectedEmails.value = val.value,
            setWebsites: val => selectedWebsites.value = val.value,
            setSocials: val => selectedSocials.value = val.value,

            // Getters
            registeredJobs: computed(() => store.getters.getUserSecondaryJobs.map(job => job.title)),
            addresses: computed(() => store.getters.getUserAddresses.map(a => a.city)),
            phoneNumbers: computed(() => store.getters.getSortedProfilePhoneNumbers),
            emails: computed(() => store.getters.getSortedProfileEmails),
            websites: computed(() => store.getters.getSortedProfileWebsites),
            socials: computed(() => store.getters.getSocials.map(s => s.network)),
            professionalName: store.getters.getUserProfessionalName,
            displayProfessionalName: store.getters.getUserDisplayProfessionalName,
            fullName: store.getters.getUserFullName
        }
    }
}


</script>

<style lang="postcss">
    .listboxButton {
    @apply relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left border sm:text-sm;
    },
    .listboxOptions {
        @apply absolute mt-1 max-h-60 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50
    }
</style>
