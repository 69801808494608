<template>
  <div class="flex justify-center gap-2"  >

              <div
             
              v-if="
                  (currentComponent == 'Index' && isAuthorized) ||
                  (currentComponent == 'Professional' && isAuthorized && !isMobile)
                "
               
               class="relative lg:mt-0 sm:w-fit lg:w-auto text-center lg:text-right top-0 lg:relative sm:inherit right-0 float-right"
              >
                <!-- class= mb-2" -->
                <button
                v-click-outside="hideToggle"
                @click="toggle"
               
                  class="a gap-1 relative inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-gray-700 shadow-sm 0 focus:outline-none"
                >
                  {{(settingMenu)?'Edit Settings':'Edit Profile'}}
                  <div class="flex justify-center">
                    <MenuIcon
                      class="h-5 w-5 l text-white-50"
                    />
                  </div>
                </button>
                <EditProfileMenu
                v-if="!settingMenu"
              class="lg:mt-0 lg:mt-0 dark:text-white col-start-0  col-span-10 md:col-start-2 md:col-span-7 lg:col-start-3 lg:col-span-7 items-center"
              :isIconVisible="isAuthorized"
              version="2"
             :class="showCreditCard"             
            >
            </EditProfileMenu>
            <EditProfileSettingMenu
                v-if="settingMenu"
              class="lg:mt-0 lg:mt-0 dark:text-white col-start-0  col-span-10 md:col-start-2 md:col-span-7 lg:col-start-3 lg:col-span-7 items-center"
              :isIconVisible="isAuthorized"
              version="2"
             :class="showCreditCard"             
            >
            </EditProfileSettingMenu>
         
              </div>
              <div
     v-if="(currentComponent == 'Index' && isAuthorized) || isAuthorized"
                :class="isMobile ? '' : 'hidden'"
                class="lg:mt-2 lg:mt-0 sm:w-fit lg:w-full lg:w-auto text-center lg:text-right top-0 lg:relative sm:inherit right-0 float-right"
              >
                <!-- <font-awesome-icon icon="fa-solid fa-pencil" class="self-center mr-2 text-gray-400"/> -->
                <button
                  class="cursor-pointer px-5 py-1 text-sm font-medium relative inline-flex items-center rounded-md border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark"
                  @click="editModeActive = true"
                >
                  Edit Header
                </button>
              </div>
              </div>
    <ProfileHeaderDialog :open="editModeActive" @close="setIsHeaderDialogOpen(false)">
      <ProfileHeaderEdit @onClose="editModeActive = false"></ProfileHeaderEdit>
    </ProfileHeaderDialog>
</template>
<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import { MenuIcon } from "@heroicons/vue/outline";
import EditProfileMenu from "@/components/widgets/profileNavbar/EditProfileMenu";
import EditProfileSettingMenu from "@/components/widgets/profileNavbar/EditProfileSettingMenu";
import ProfileHeaderEdit from "@/components/profile/components/profileHeader/ProfileHeaderEdit";
import ProfileHeaderDialog from "@/components/profile/components/profileHeader/ProfileHeaderDialog";

export default {
  name: "EditProfileHeaderButton",
  components:{
    MenuIcon,
    EditProfileMenu,
    EditProfileSettingMenu,
    ProfileHeaderEdit,
    ProfileHeaderDialog
  },
  props: {
    
    currentComponent:{
      type:String,
      default:'Index'
    },
    settingMenu:{
      type: Boolean,
      default: false
    }   
  },
  data(){
      return{
        showCreditCard: "hidden",
        config: {
         events: ["dblclick", "click"]
      }
      }
  },
  // methods()
  updated() {
    // Close modal with 'esc' key
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27) {
         this.showCreditCard = "hidden";
      }
    });
  },
  setup() {
    const openMobile = ref(false);
    const editModeActive = ref(false);
    const store = useStore();
    const isAuthorized = computed(() => store.getters.isUserAuthorizedToEdit);
    const { width } = useBreakpoints();
    return{
      editModeActive,
      openMobile,
      store,
      isAuthorized,
      isMobile: computed(() => width.value < 1024),
      setIsHeaderDialogOpen(value) {
        editModeActive.value = value;
      },
    }
  },
  methods: {
    toggle() {
      if (this.showCreditCard === "hidden") {
        this.showCreditCard = "";
      } else {
        this.showCreditCard = "hidden";
      }
    },
     hideToggle() {
       setTimeout(() => {
        this.showCreditCard = "hidden"; // method to call when user is done typing
      }, 300);
   
    },
  },
 
};
</script>
