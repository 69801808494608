<template>
  <div>
    <div class="flex">
      <div id="" class="w-full grid grid-cols-9 lg:grid-cols-10 dark:bg-primaryDark">
        <div
          class="h-28 w-28 lg:h-36 lg:mr-4 lg:w-36 lg:justify-self-end place-self-center col-start-5 lg:col-start-2 col-span-1 overflow-hidden flex justify-center"
        >
          <img
            v-if="editMode"
            @click="imagePoiSelectorOn = !imagePoiSelectorOn"
            :src="profilePicture ? profilePicture : ''"
            class="rounded-full cursor-pointer w-full object-cover"
          />
          <img
            v-else
            @click="displayProfilePicturesOn = !displayProfilePicturesOn"
            :src="profilePicture ? profilePicture : ''"
            class="rounded-full cursor-pointer w-full object-cover"
          />
        </div>
        <MediaImageModalWindow
          :images="profilePictures"
          :modalActive="displayProfilePicturesOn"
          @close="displayProfilePicturesOn = false"
          :curIdx="getIndexOfImage(profilePictures, profilePicture)"
        />
        <div class="col-span-7 lg:col-start-3 col-start-2 dark:text-gray-300">
          <div
            class="flex flex-wrap text-center items-baseline lg:justify-start justify-center"
          >
            <h2 v-if="header && header.name" class="font-25 font-bold mt-1 lg:mt-0">
              {{ header.name }}
            </h2>
            <div
              v-if="about && about.pronouns"
              class="md:text-2xl text-xl px-3 text-gray-400 lowercase"
            >
              ({{ about.pronouns }})
            </div>
          </div>

          <!-- Header line -->
          <div
            class="flex gap-3 flex-wrap text-center justify-center lg:justify-between border-b border-gray-400 pt-0 lg:pt-1 pb-1"
          >
            <div
              class="flex flex-wrap justify-center md:text-base text-sm gap-2 lg:gap-3"
            >
              <div v-if="(header.jobs)"
                class="flex flex-wrap my-2 lg:my-0 justify-center gap-x-3 lg:gap-x-0 lg:gap-3"
              >
                <p
                  v-for="(job, idx) in header.jobs"
                  :key="idx"
                  class="text-black capitalize small-caps text-base font-normal dark:text-white"
                >
                  {{ job }}
                </p>
              </div>

              <div class="flex flex-wrap gap-3 justify-center md:text-base text-sm">
                <p
                  v-for="(loc, idx) in cities"
                  class="flex text-gray-500 dark:text-gray-400 self-center"
                  :key="idx"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-location-dot"
                    class="self-center text-gray-400"
                    fixed-width
                  />
                  {{ sentenceCase(loc) }}
                </p>
              </div>
            </div>

            <!-- Code duplicated below for mobile -->
            <div
              v-if="editMode && !isMobile"
              class="flex dark:text-gray-400 text-gray-500"
            >
              <font-awesome-icon
                icon="fa-solid fa-pencil"
                class="self-center lg:mr-2 text-gray-400 dark:text-white"
              />
              <button @click="editModeActive = true" class="dark:text-white">Edit header</button>
            </div>
          </div>

          <!-- Below header line -->
          <div
            class="flex lg:justify-between lg:flex-row flex-col pt-3 lg:pt-1 gap-3 flex-wrap"
          >
            <div
              class="flex flex-wrap lg:justify-start justify-center text-xs lg:text-base"
            >
              <template v-if="!isMobile">
                <ContactDropdown :items="phones" :preferLabel="false">
                  <font-awesome-icon fixed-width icon="fa-solid fa-phone w-2" class="dark:text-white "/>
                </ContactDropdown>
                <ContactDropdown :items="emails">
                  <font-awesome-icon fixed-width icon="fa-solid fa-envelope"  class="dark:text-white " />
                </ContactDropdown>
                <ContactDropdown :items="links" :preferLabel="true">
                  <font-awesome-icon fixed-width icon="fa-solid fa-link" class="dark:text-white " />
                </ContactDropdown>
              </template>

              <template v-else>
                <div class="flex flex-row px-4">
                  <font-awesome-icon
                    @click="phoneSheetActive = !phoneSheetActive"
                    icon="fa-solid fa-phone"
                    size="2xl"
                    class="dark:text-white w-4 h-4 lg:w-6 lg:h-6 self-center text-gray-800  border-2 border-gray-500 dark:border-gray-200 rounded-full p-3 cursor-pointer hover:bg-gray-300 dark-hover:bg-gray-800"
                    :class="phoneSheetActive ? 'bg-gray-500 dark:bg-gray-700' : ''"
                  />
                  <!-- <p class="text-justify self-center line-clamp-1">{{}}</p> -->
                </div>
                <div class="flex flex-row px-4">
                  <font-awesome-icon
                    @click="emailSheetActive = !emailSheetActive"
                    icon="fa-solid fa-envelope"
                    size="2xl"
                    class="dark:text-white w-4 h-4 lg:w-6 lg:h-6 self-center text-gray-800  border-2 border-gray-500 dark:border-gray-200 rounded-full p-3 cursor-pointer hover:bg-gray-300 dark-hover:bg-gray-800"
                    :class="emailSheetActive ? 'bg-gray-500 dark:bg-gray-700' : ''"
                  />
                </div>
                <div class="flex flex-row px-4">
                  <font-awesome-icon
                    @click="linkSheetActive = !linkSheetActive"
                    icon="fa-solid fa-link"
                    size="2xl"
                    class="dark:text-white w-4 h-4 lg:w-6 lg:h-6 self-center text-gray-800  border-2 border-gray-500 dark:border-gray-200 rounded-full p-3 cursor-pointer hover:bg-gray-300 dark-hover:bg-gray-800"
                    :class="linkSheetActive ? 'bg-gray-500 dark:bg-gray-700' : ''"
                  />
                </div>
              </template>
            </div>

            <div
              class="items-center flex max-h-6 lg:justify-start justify-center item-center"
            >
              <a
                v-for="(social, idx) in header.socials"
                :key="idx"
                :href="toRealLink(social)"
                target="_blank"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="social.username"
              >
                <img
                  :alt="social.username"
                  class="h-full w-5 lg:mx-0 mx-2 cursor-pointer"
                  :src="[darkModeOn ? social.wLogo : social.logo]"
                />
              </a>
              <VCard v-if="enableVcard" class="h-full lg:mx-1 mx-2" />
            </div>

            <!-- Code duplicated above for desktop -->
          </div>
        </div>

        <slot></slot>
      </div>
    </div>
    <ContactSheet 
      :items="phones"
      :active="phoneSheetActive"
      @close="phoneSheetActive = false"
    />
    <ContactSheet
      :items="emails"
      :active="emailSheetActive"
      @close="emailSheetActive = false"
    />
    <ContactSheet
      :items="links"
      :active="linkSheetActive"
      @close="linkSheetActive = false"
    />

    <ProfilePhotoEdit
      :modalActive="imagePoiSelectorOn"
      :imagePoiSelectorOn="imagePoiSelectorOn"
      @close="setIsPhotoEditOpen(false)"
    >
    </ProfilePhotoEdit>

    <ProfileHeaderDialog :open="editModeActive" @close="setIsHeaderDialogOpen(false)">
      <ProfileHeaderEdit @onClose="editModeActive = false"></ProfileHeaderEdit>
    </ProfileHeaderDialog>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
// import Modal from '@/components/widgets/Modal.vue'
import ProfileHeaderEdit from "@/components/profile/components/profileHeader/ProfileHeaderEdit";
import MediaImageModalWindow from "@/components/widgets/mediaImageWidget/MediaImageModalWindow";
import ProfilePhotoEdit from "./ProfilePhotoEdit.vue";
import VCard from "@/components/widgets/VCard";
import ProfileHeaderDialog from "./ProfileHeaderDialog";
import capitalize from "../../../../utils/Capitalize";
import ContactSheet from "./ContactSheet";
import ContactDropdown from "./ContactDropdown";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import { PhotoAlbumNames } from "@/constants";

const toRealLink = (href) => {
  if (href == null) return "";

  if (href.url) {
    if (href.url.includes("https") || href.url.includes("http")) {
      return href.url;
    } else {
      if (href.network) return "https://" + href.network + ".com/" + href.url;
      else return "//" + href.url;
    }
  } else {
    if (href.url && (href.url.includes("https") || href.url.includes("http"))) {
      return href;
    } else {
      return "//" + href;
    }
  }
};

export default {
  name: "ProfileHeader",
  components: {
    VCard,
    ProfileHeaderEdit,
    MediaImageModalWindow,
    ProfilePhotoEdit,
    ProfileHeaderDialog,
    ContactSheet,
    ContactDropdown,
  },
  props: {
    editMode: Boolean,
  },
  methods: {
    sentenceCase(string) {
      //  Makes the first char of a string uppercase
      //  and the following chars lowercase
      return capitalize(string);
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const { width } = useBreakpoints();

    const editModeActive = ref(false);
    const phoneSheetActive = ref(false);
    const phoneDropdownActive = ref(false);
    const emailSheetActive = ref(false);
    const emailDropdownActive = ref(false);
    const linkSheetActive = ref(false);
    const linkDropdownActive = ref(false);
    const imagePoiSelectorOn = ref(false);
    const displayProfilePicturesOn = ref(false);
    const isUserProfile = ref(route.params.id === store.getters.getId || props.editMode);
    return {
      toRealLink,

      store,
      editModeActive,
      phoneSheetActive,
      phoneDropdownActive,
      displayProfilePicturesOn,
      emailSheetActive,
      emailDropdownActive,
      linkSheetActive,
      linkDropdownActive,
      imagePoiSelectorOn,
      isUserProfile,
      profilePictures: computed(() =>
        store.getters.getProfileAlbum(PhotoAlbumNames.ProfilePhotos)
      ),
      isMobile: computed(() => width.value < 1024),
      darkModeOn: computed(() => store.getters.getDarkMode),
      profilePicture: computed(() => store.getters.getProfilePicture),
      header: computed(() => store.getters.getProfileHeader),
      cities: computed(() => store.getters.getProfileCities),
      websites: computed(() => store.getters.getProfileWebsites),
      about: computed(() => store.getters.getProfileAbout),
      enableVcard: computed(() => store.getters.getEnableVcard),
      phones: computed(() =>
        store.getters.getSortedProfilePhoneNumbers
          .filter((item) => item != null)
          .map((phone) => ({
            ...phone,
            label: phone?.label,
            address: phone?.phoneNumber,
            link: `tel:${phone?.phoneNumber}`,
          }))
      ),
      getIndexOfImage(images, target) {
        for (let i = 0; i < images.length; i++) {
          if (images[i].croppedImage === target) return i;
        }
        return 0;
      },
      emails: computed(() =>
        store.getters.getSortedProfileEmails
          .filter((item) => item != null)
          .map((email) => ({
            ...email,
            label: email?.label,
            address: email?.email,
            link: `mailto:${email?.email}`,
          }))
      ),
      links: computed(() =>
        store.getters.getSortedProfileWebsites
          .filter((item) => item != null)
          .map((link) => ({
            ...link,
            label: link?.title,
            address: link?.url,
            link: toRealLink(link),
          }))
      ),
      setIsHeaderDialogOpen(value) {
        editModeActive.value = value;
      },
      setIsPhotoEditOpen(value) {
        imagePoiSelectorOn.value = value;
      },
    };
  },
};
</script>
