<template>
 <div class="w-full text-center ">         
<div class="absolute w-48 sm:w-60 shadow text-left z-10 edit-menu"> 
          <router-link active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark" :to="{ name: 'editProfileContact', params: { id: id } }"
          class=" flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3	text-sm items-center  font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">
          <font-awesome-icon :icon="['far', 'address-book']"  class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0 h-4 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" /> 
          Contact</router-link>


     <router-link active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark" :to="{ name: 'editProfileSkill', params: { id: id } }"
          class=" flex  gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3	text-sm items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">
          <font-awesome-icon :icon="['fas', 'wrench']"  class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
          Skills</router-link>

          <router-link active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"  :to="{ name: 'editProfilePhysical', params: { id: id } }"
          class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3	text-sm  items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">
          <font-awesome-icon :icon="['fas', 'person']"  class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
          Physical</router-link>

    <router-link active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark" :to="{ name: 'editProfileDiversity', params: { id: id } }"
          class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3	text-sm items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">
          <font-awesome-icon :icon="['fab', 'accessible-icon']"   class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
          Diversity</router-link>

          <router-link active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark" :to="{ name: 'editProfileMedical', params: { id: id } }"
          class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3	text-sm items-center  font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">
          <font-awesome-icon :icon="['fas', 'folder-plus']"  class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
          Medical</router-link>

        <router-link active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark" :to="{ name: 'editProfileTravel', params: { id: id } }"
          class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3	text-sm items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">
          <font-awesome-icon :icon="['fas', 'plane-departure']"  class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
          Travel</router-link>
 
     <router-link active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark" :to="{ name: 'editProfileProgramme', params: { id: id } }"
          class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3	text-sm items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">
          <font-awesome-icon :icon="['fas', 'book-open']"   class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
          Programme</router-link>

           <router-link active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"  :to="{ name: 'editProfileAwards', params: { id: id } }"
          class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3	text-sm items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">
          <font-awesome-icon :icon="['fas', 'trophy']"  class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
            Awards & nominations </router-link>
            <router-link active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"  :to="{ name: 'editAgents', params: { id: id } }"
          class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3	text-sm items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">
          <font-awesome-icon :icon="['fas', 'user-secret']"  class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
          Agents </router-link>
            <router-link active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"  :to="{ name: 'editWorkEducation', params: { id: id } }"
          class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3	text-sm items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">
          <font-awesome-icon :icon="['fas', 'laptop-file']"  class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
          Work & Education </router-link>
            <router-link active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"  :to="{ name: 'editMeasurements', params: { id: id } }"
          class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3	text-sm items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700">
          <font-awesome-icon :icon="['fas', 'weight-scale']"  class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
          Measurements </router-link>
  </div >
</div>
</template>
<script>
// import { MenuIcon } from "@heroicons/vue/outline";
// import { MenuIcon } from "@heroicons/vue/outline/solid";
import { ref,computed } from "vue";
import { useStore } from 'vuex'
// import { EyeIcon, EyeOffIcon } from "@heroicons/vue/solid";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
export default {
  name: "EditProfileMenu",
  // components: { MenuIcon,},
  
  props: {
    navigation: {
      type: Array,
    },
    noHide: {
      type: Boolean,
      default: false
    },
    subNav: {  // Is the navbar nested within another navbar?
      type: Boolean,
      default: false
    },
    version: {
      required: false,
      default: "1"
    },
    isIconVisible: {
      required: false,
      default: false
    },
    menuClass:{
      required:false,
      type:String
    }
  },
  // methods()
  setup(props) {
    const open = ref(false)
    const { width } = useBreakpoints();
    const store = useStore()
    return {
      open,
      isMobile: computed(() => width.value < 1024),
      id: computed(() => store.getters.getId),
      fetchButtonClass(btnItem) {
        // TODO:  This might need to be cleaned up or changed.
        //        A lot going on here.
        if (props.subNav) {
          if (btnItem.current) {
            return ' '
          } else {
            return 'text-gray-400 hover:text-gray-900 hover:bg-gray-50  dark:bg-primaryDark dark-hover:bg-primaryDark dark-hover:text-gray-500 dark:text-gray-400'
          }
        } else {
          if (btnItem.current) {
            // TODO: Maybe add accent color underline to current element?
            return ' text-gray-700 hover:bg-white border-b-2 border-gray-500 dark:bg-primaryDark dark-hover:text-gray-500 dark-hover:bg-primaryDark'
          } else {
            return 'text-gray-400 border-b-2 border-primary dark:border-primaryDark hover:text-gray-900 hover:bg-gray-50 dark:bg-primaryDark dark-hover:bg-primaryDark dark-hover:text-gray-500'
          }
        }
      }
    }
  }
}
</script>
<style>
.dark-mode .edit-menu a:hover svg{
    color: #1E1E1E;
}
</style>