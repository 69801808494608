<template>
  <!--
    Pass the `isOpen` ref to the `open` prop, and use the `close` event
    to set the ref back to `false` when the user clicks outside of
    the dialog or presses the escape key.
  -->
  <Dialog :open="isOpen" @close="setIsOpen" class="relative z-40">
      <!-- The backdrop, rendered as a fixed sibling to the panel container -->
    <div class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" aria-hidden="true" />
    <!-- Full-screen scrollable container -->
    <div class="fixed inset-0 overflow-y-auto">
      <!-- Container to center the panel -->
      <div class="flex h-full items-center justify-center max-w-2xl lg:w-full 2xl:2/6 p-4 lg:p-0 lg:w-2/5 sm:w-full overflow-hidden inline-block rounded-lg transform w-screen transition-all sm:align-middle mx-auto my-0 overflow-visible">

    <DialogPanel
      :class="[darkModeEnabled ? 'bg-primaryDark text-white' : 'bg-primary', 'overflow-y-scroll no-scroll-bar overflow-hidden h-auto max-h-100 lg:max-h-auto  w-full max-w-3xl rounded-xl p-4 shadow-xl',]">
      <DialogTitle class="text-3xl px-4">Edit Header</DialogTitle>
      <DialogDescription class="px-4">
        Select what information is displayed in your header. This information is editable in the Contacts section of your profile
      </DialogDescription>
      <slot></slot>
      <!--
        You can render additional buttons to dismiss your dialog by setting your
        `isOpen` state to `false`.
      -->
      <!-- <button @click="$emit('close')">Cancel</button>
      <button @click="handleDeactivate">Deactivate</button> -->
    </DialogPanel>
    </div>
    </div>
  </Dialog>
</template>

<script setup>
  import { ref, computed } from 'vue'
  import {useStore} from "vuex";
  import {
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
  } from '@headlessui/vue'

  // The open/closed state lives outside of the Dialog and
  // is managed by you.
  const isOpen = ref(true)


  const store = useStore()
  const darkModeEnabled = computed(() => store.getters.getDarkMode)

  function setIsOpen(value) {
    isOpen.value = value
  }

</script>