<template>
  <div class="w-full text-center">
    <div class="absolute w-48 sm:w-60 shadow text-left z-10 edit-menu">
      <router-link
        active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"
        :to="{ name: 'Settings', params: { id: id } }"
        class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3 text-sm items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700"
      >
      <font-awesome-icon
          :icon="['fas', 'user']"
          class="text-grey-400 text-md dark:text-white dark:hover:text-black font-normal text-black flex items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out pl-1"
          aria-hidden="true"
        />
        {{fullName}}</router-link
      >

      <router-link
        active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"
        :to="{ name: 'SettingPrivacy', params: { id: id } }"
        class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3 text-sm items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700"
      >
      <svg width="24" height="24" viewBox="0 0 16 20" class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-7 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M8 0C5.243 0 3 2.243 3 5V8H2C0.897 8 0 8.897 0 10V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V10C16 8.897 15.103 8 14 8H13V5C13 2.243 10.757 0 8 0ZM14 10L14.002 18H2V10H14ZM5 8V5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V8H5Z" fill="currentColor" />
              </svg>
        Privacy</router-link
      >

      <router-link
        active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"
        :to="{ name: 'SettingNotification', params: { id: id } }"
        class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3 text-sm items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700"
      >
      <font-awesome-icon
          :icon="['far', 'bell']"
          class="text-grey-400 text-md dark:text-white font-normal text-black flex items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out"
          aria-hidden="true"
        />
        Notification</router-link
      >

      <router-link
        active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"
        :to="{ name: 'SettingSecurity', params: { id: id } }"
        class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3 text-sm items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700"
      >
      <svg  viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-7 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out">
          <path d="M9 0L0 4V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V4L9 0ZM9 10.99H16C15.47 15.11 12.72 18.78 9 19.93V11H2V5.3L9 2.19V10.99Z" fill="currentColor"/>
      </svg>

   
        Security & Login</router-link>

      <router-link
        active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"
        :to="{ name: 'SettingSubscription', params: { id: id } }"
        class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3 text-sm items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700"
      >
      <svg  viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-grey-400 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-8 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out">
        <path d="M8.5 14.6667C8.5 15.9553 9.54467 17 10.8333 17H13C14.3807 17 15.5 15.8807 15.5 14.5C15.5 13.1193 14.3807 12 13 12H11C9.61929 12 8.5 10.8807 8.5 9.5C8.5 8.11929 9.61929 7 11 7H13.1667C14.4553 7 15.5 8.04467 15.5 9.33333M12 5.5V7M12 17V18.5M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" stroke-width="2" />
      </svg>
      
        Subscription</router-link
      >

      <router-link
        active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"
        :to="{ name: 'SettingRegional', params: { id: id } }"
        class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3 text-sm items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700"
      >
      <font-awesome-icon
          :icon="['fas', 'earth-americas']"
          class="text-grey-400 text-md dark:text-white font-normal text-black flex items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out"
          aria-hidden="true"
        />
        Regional settings</router-link>
      <router-link
        active-class="active text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"
        :to="{ name: 'SettingNavigation', params: { id: id } }"
        class="flex gap-2 text-gray-500 bg-white hover:bg-gray-100 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-3 text-sm items-center font-medium sm:pl-5 sm:pr-6 dark:bg-primaryDark dark:text-white dark:border-gray-700"
      >
      <font-awesome-icon
          :icon="['fas', 'anchor']"
          class="text-grey-400 text-md dark:text-white dark-hover:text-black font-normal text-black flex items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out"
          aria-hidden="true"
        />
        Navigation</router-link>




     
    </div>
  </div>
</template>
<script>
// import { MenuIcon } from "@heroicons/vue/outline";
// import { MenuIcon } from "@heroicons/vue/outline/solid";
import { ref, computed } from "vue";
import { useStore } from "vuex";
// import { EyeIcon, EyeOffIcon } from "@heroicons/vue/solid";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
export default {
  name: "EditProfileSettingMenu",
  // components: { MenuIcon,},

  props: {
    navigation: {
      type: Array,
    },
    noHide: {
      type: Boolean,
      default: false,
    },
    subNav: {
      // Is the navbar nested within another navbar?
      type: Boolean,
      default: false,
    },
    version: {
      required: false,
      default: "1",
    },
    isIconVisible: {
      required: false,
      default: false,
    },
    menuClass: {
      required: false,
      type: String,
    },
  },
  // methods()
  setup(props) {
    const open = ref(false);
    const { width } = useBreakpoints();
    const store = useStore();
    return {
      open,
      isMobile: computed(() => width.value < 1024),
      fullName: store.getters.getUserFullName,
      id: computed(() => store.getters.getId),
      fetchButtonClass(btnItem) {
        // TODO:  This might need to be cleaned up or changed.
        //        A lot going on here.
        if (props.subNav) {
          if (btnItem.current) {
            return " ";
          } else {
            return "text-gray-400 hover:text-gray-900 hover:bg-gray-50  dark:bg-primaryDark dark-hover:bg-primaryDark dark-hover:text-gray-500 dark:text-gray-400";
          }
        } else {
          if (btnItem.current) {
            // TODO: Maybe add accent color underline to current element?
            return " text-gray-700 hover:bg-white border-b-2 border-gray-500 dark:bg-primaryDark dark-hover:text-gray-500 dark-hover:bg-primaryDark";
          } else {
            return "text-gray-400 border-b-2 border-primary dark:border-primaryDark hover:text-gray-900 hover:bg-gray-50 dark:bg-primaryDark dark-hover:bg-primaryDark dark-hover:text-gray-500";
          }
        }
      },
    };
  },
};
</script>
<style>
.dark-mode .edit-menu a:hover svg {
  color: #1e1e1e;
}
</style>
